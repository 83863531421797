import { VideoInterface } from "../interfaces/video.interface";

export function filterQuery(searchIndexes: string[], searchParams: string) {
  return searchIndexes
    .filter((index) => index)
    .some((index) =>
      searchParams
        .trim()
        .split(" ")
        .some((param) => index.toLowerCase().includes(param.toLowerCase()))
    );
}

export function filterCareflowVideos(videos: VideoInterface[], isCareflowEnabled: boolean): VideoInterface[] {
  return videos.filter(x => !!x.careflow === isCareflowEnabled || x.type === 'webinar');
}