/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import headerStyles from "../styles/components/header.module.scss";
import reportStyles from "../styles/components/reporting.module.scss";
import cardStyles from "../styles/components/card.module.scss";
import searchImage from "../assets/search.svg";
import { reportData } from "../utils/data/reportingData";
import { REPORTS, TRACKING } from "../utils/constants/constants";
import { ReportInterface } from "../utils/interfaces/report.interface";
import listStyles from "../styles/components/list.module.scss";
import HighLightText from "../utils/helpers/highlightText";
import useTrackComponentTime from "../utils/helpers/useTrackComponentTime";
import { filterQuery } from "../utils/helpers/filter";

const Reporting: React.FunctionComponent = () => {
  const trackComponent = useTrackComponentTime(TRACKING.Reporting.name);
  const originalReport = reportData.sort((a, b) =>
    a.title.localeCompare(b.title)
  );
  const [report, setReport] = useState<ReportInterface[]>(originalReport);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    trackComponent();
  }, []);

  useEffect(() => {
    query && query.length > 0
      ? setReport(filterReport())
      : setReport(originalReport);
  }, [query]);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentIndex(0);
    setQuery(e.target.value);
  };

  const filterReport = () => {
    return originalReport.filter((report, index) => {
      let primaryUseCaseSubList: string[] = [];
      report.primaryUseCases.map((useCase) =>
        useCase.bulletList
          ?.filter((list) => list)
          .map((list) => primaryUseCaseSubList.push(list))
      );
      return filterQuery(
        [
          REPORTS.containerTitles[0],
          REPORTS.containerTitles[1],
          report.title,
          report.description.text,
          ...report.primaryUseCases.map((useCase) => useCase.text),
          ...report.similarReports.map((simRep) => simRep.text),
          ...report.similarReports.map((simRep) => simRep.clientName),
          ...primaryUseCaseSubList,
        ],
        query
      );
    });
  };

  return (
    <>
      <section className={reportStyles.report}>
        <div className={reportStyles.actionPanel}>
          <div className={reportStyles.actionPanel__header}>
            <h3 className={reportStyles.actionPanel__title}>
              {REPORTS.title} ({report.length})
            </h3>
            <div className={reportStyles.search}>
              <input
                placeholder={REPORTS.searchPlaceHolder}
                type="text"
                className={`${headerStyles["c-search"]} ${headerStyles["c-search--lg"]}`}
                style={{ backgroundImage: `url(${searchImage})` }}
                value={query}
                onChange={handleQueryChange}
              />
            </div>
          </div>
          <div className={reportStyles.actionPanel__scrollBox}>
            <ul className={reportStyles.actionPanel__list}>
              {report.map((report, index) => {
                return (
                  <li
                    key={index}
                    className={`${reportStyles.actionPanel__listItem} ${
                      currentIndex === index &&
                      reportStyles["actionPanel__listItem--active"]
                    }`}
                    onClick={() => setCurrentIndex(index)}
                  >
                    {report.title}
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div
          className={`${reportStyles.contentPanel} ${reportStyles["actionPanel__scrollBox"]}`}
        >
          {report.length > 0 && (
            <div className={reportStyles.contentPanel__header}>
              <h3 className={reportStyles.contentPanel__title}>
                <HighLightText
                  text={report[currentIndex]?.title}
                  highlight={query}
                />
              </h3>
              <p className={reportStyles.contentPanel__text}>
                <HighLightText
                  text={report[currentIndex]?.description.text}
                  highlight={query}
                />
                {report[currentIndex]?.description?.bulletList && (
                  <ul
                    className={`${listStyles["c-list"]} ${listStyles["c-list--decimal"]} ${listStyles["c-list--sub"]}`}
                  >
                    {report[currentIndex].description.bulletList?.map(
                      (list, index) => (
                        <li
                          key={index}
                          className={`${listStyles["c-list__item"]} ${listStyles["c-list__item--sub"]}`}
                        >
                          <HighLightText text={list} highlight={query} />
                        </li>
                      )
                    )}
                  </ul>
                )}
              </p>
            </div>
          )}

          {report.length > 0 && (
            <div className={cardStyles["c-card"]}>
              <div className={cardStyles["c-card__header"]}>
                <h3 className={cardStyles["c-card__title"]}>
                  <HighLightText
                    text={REPORTS.containerTitles[0]}
                    highlight={query}
                  />
                </h3>
              </div>
              <div className={cardStyles["c-card__body"]}>
                <ul className={listStyles["c-list"]}>
                  {report[currentIndex]?.primaryUseCases.map((item, index) => {
                    return (
                      <li key={index} className={listStyles["c-list__item"]}>
                        <HighLightText text={item.text} highlight={query} />
                        {item.bulletList && (
                          <ul
                            className={`${listStyles["c-list"]} ${listStyles["c-list--decimal"]} ${listStyles["c-list--sub"]}`}
                          >
                            {item.bulletList.map((list, index) => (
                              <li
                                key={index}
                                className={`${listStyles["c-list__item"]} ${listStyles["c-list__item--sub"]}`}
                              >
                                <HighLightText text={list} highlight={query} />
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          )}

          {report.length > 0 && (
            <div className={cardStyles["c-card"]}>
              <div className={cardStyles["c-card__header"]}>
                <h3 className={cardStyles["c-card__title"]}>
                  <HighLightText
                    text={REPORTS.containerTitles[1]}
                    highlight={query}
                  />
                </h3>
              </div>
              <div className={cardStyles["c-card__body"]}>
                {report[currentIndex]?.similarReports.map((item, index) => {
                  return (
                    <p key={index} className={cardStyles["c-card__content"]}>
                      <span className={cardStyles["c-card__label"]}>
                        <HighLightText
                          text={item.clientName}
                          highlight={query}
                        />
                      </span>
                      <span className={cardStyles["c-card__value"]}>
                        <HighLightText text={item.text} highlight={query} />
                      </span>
                    </p>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default Reporting;
